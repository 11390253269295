import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Aside from "../components/aside"
import {graphql, Link} from "gatsby"
import {useEffect} from "react"

export default function Procedure({data}) {
    const posts = data.posts.edges.map(({node}) => node.frontmatter)
    const gallery = data.gallery


    const post = data.post
    const {
        title,
        metaTitle,
        metaDescription,
        slug,
        price,
        duration,
        absence
    } = post.frontmatter
    const testimonials = data?.testimonials?.edges?.map(({node}) => node)
        .filter((testimonial) => {
            return testimonial.slug === slug
        })

    const seo = {
        title: metaTitle,
        description: metaDescription,
        url: "https://mpastucha.pl" + slug
    }

    useEffect(() => {
        let interval = setInterval(() => {
            if (typeof window !== "undefined" && window.ProceduresPageInit) {
                clearInterval(interval)
                window.ProceduresPageInit()
            }
        })
    })

    return (
        <Layout headerClasses={"mainheader-subpage mainheader-white"} classes={"bg-creme"}>
            <SEO
                title={seo.title}
                description={seo.description}
                url={seo.url}
            />
            <header id="top" className="fake-placeholder"/>
            <a href="tel:913112228" className="phonelink"><img src="/images/phone.svg"
                                                               alt="Ikona telefon"/></a>
            <div className="fullwidth"><img src="/uploads/2015/09/procedures-1920x730-1920x730-c-default.jpg"
                                            alt="procedura"/></div>
            <div className="container-fluid sct">
                <div className="row pad-l pad-r">
                    <Aside posts={posts} actual={slug}/>
                    <section className="col-6md sct-content">
                        <div className="row">
                            <div className="col-12">
                                <h1 dangerouslySetInnerHTML={{__html: title}}/>
                            </div>
                            <div className="col-12">
                                <div className="btn-list mrg-b-big pull-left ">
                                    {price && (<>
                                        <div className="btn btn-border-primary">{price[0]}</div>
                                        <div className="btn btn-border-primary">{duration[0]}</div>
                                        <div className="btn btn-border-primary">{absence[0]}</div>
                                    </>)}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="h2 mrg-b-big"><a href="/jak-przygotowac-sie-do-zabiegu/"> Jak
                                    przygotować się do
                                    zabiegu?</a></div>
                                <div dangerouslySetInnerHTML={{__html: post.html}}/>
                            </div>
                        </div>
                    </section>
                    <aside className="col-3md mrg-b">
                        <Link to="/kontakt/" className="btn btn-contrast btn-primary">Zarejestruj się</Link>
                        {gallery && gallery.images.length > 0 && <div className="mrg-tb">
                            <div className="row">
                                <div className="txt-cnt">
                                    <h3>Galeria zabiegu</h3>
                                </div>
                                <div className="thumbnails imageGallery">
                                    {gallery.images.slice(0, 10).map((item, key) => (
                                        <div className="col-12md col-6xs" key={key}>
                                            <div className="thumbnails-photo">
                                                <a href={item.fullsize} data-size="1731x578">
                                                    <img
                                                        src={item.thumbnail}
                                                        alt="przed i po"/>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>}
                        {testimonials.map((testimonial, key) => (
                            <div className="mrg-tb" key={key}>
                                <Link to="/opinie/">
                                    <div className="opinion opinion-aside">
                                        <h4>{testimonial.procedure}</h4>
                                        <p dangerouslySetInnerHTML={{__html: testimonial.content}}/>
                                    </div>
                                    <div className="txt-al-r txt-small opinion-person txt-dark">
                                        <p>~{testimonial.name}</p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </aside>
                </div>
            </div>

        </Layout>
    )
}

export const pageQuery = graphql`
    query Procedure($slug: String) {
        post: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                metaTitle
                metaDescription
                slug
                price
                duration
                absence
            }
        },
        posts: allMarkdownRemark {
            edges {
                node {
                    html
                    frontmatter {
                        slug
                        title
                        category
                    }
                }
            }
        },
        gallery: galleryYaml(slug: { eq: $slug}) {
            slug
            images {
                thumbnail
                fullsize
            }
        }
        testimonials: allTestimonialsYaml {
            edges {
                node {
                    slug
                    procedure
                    content
                    name
                }
            }
        }
    }`
